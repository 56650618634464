<template>
  <v-app>
    <v-main>
      <!-- <NavbarComponent /> -->
      <HeaderComponent />
      <router-view />
      <!-- <FooterComponent /> -->
      <FooterCommon />
    </v-main>
  </v-app>
</template>

<script>
// import NavbarComponent from "./components/homepage/NavbarComponent.vue"
import HeaderComponent from "./components/allCommon/HeaderComponent.vue";
// import FooterComponent from "./components/homepage/FooterComponent.vue";
import FooterCommon from "./components/allCommon/FooterCommon.vue";

export default {
  name: "App",

  components: {
    // NavbarComponent,
    HeaderComponent,
    // FooterComponent,
    FooterCommon,
  },

  // data: () => ({
  //   //
  // }),
};
</script>
<style lang="scss">
html,
.v-application {
  font-family: Poppins !important;
  // scroll-behavior: smooth;
}
.cta-btn {
  display: inline-block;
  padding: 15px 35px;
  text-transform: uppercase;
  background: #ff3f2d;
  color: #fff !important;
  text-decoration: none;
  border-radius: 12px;
  font-weight: 300;
}

// @media only screen and (min-width: 901px) and (max-width: 1023px) {
// a{
//   font-size: 10px !important;
// }
// }


@media only screen and (min-width: 1024px) and (max-width: 1438px) {
  p {
    font-size: 12px !important;
  }
  // a {
  //   font-size: 10px !important;
  // }
}
@media only screen and (min-width: 1439px) and (max-width: 1903px) {
  .container {
    max-width: 95% !important;
  }
  a {
    font-size: 14px !important;
  }
  p {
    font-size: 14px !important;
  }
}
@media (max-width: 900px) {
.row{
  margin:0;
}
}
</style>
