<template>
  <div class="mobile-container-main">
    <v-row align="center" class="row-container">
      <v-col>
        <v-app-bar-nav-icon @click="drawer = true"></v-app-bar-nav-icon>
      </v-col>
      <v-col>
        <router-link to="/"
          ><img src="@/assets/Narmada logo_narmada orange copy 4.png" class="mobile-logo" alt=""
        /></router-link>
      </v-col>
      <v-col> </v-col>
    </v-row>
    <v-navigation-drawer v-model="drawer" absolute temporary>
      <v-list nav dense class="pa-0">
        <v-list-item-group
          v-model="group"
          active-class="deep-purple--text text--accent-4"
          class="pa-0"
        >
          <!-- <div
            style="text-align: center; padding-bottom: 20px; padding-top: 20px"
          >
            <router-link to="/"
              ><img src="/assets/NarmadaOrange.png" width="160" alt=""
            /></router-link>
          </div> -->

          <v-expansion-panels accordion>
            <v-expansion-panel v-for="(menuItem, i) in menu" :key="i">
              <v-expansion-panel-header
                expand-icon="mdi-plus"
                v-if="menuItem.subMenu != null"
              >
                <router-link :to="menuItem.link">
                  {{ menuItem.title }}</router-link
                >
              </v-expansion-panel-header>
              <v-expansion-panel-header
                expand-icon="mdi-plus"
                hide-actions
                v-else
              >
                <router-link :to="menuItem.link">
                  {{ menuItem.title }}</router-link
                >
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <ul>
                  <li
                    v-for="(subItem, j) in menuItem.subMenu"
                    :key="j"
                    :class="'disable' + j"
                  >
                    <router-link :to="subItem.link">{{
                      subItem.title
                    }}</router-link>

                    <!-- <ul>
                      <li v-for="(sub, l) in subItem.subMenu" :key="l"> -->
                        <!-- <router-link :to="sub.link">{{
                          sub.title
                        }}</router-link> -->
                        <!-- <a :href="sub.link">{{
                          sub.title
                        }}</a>
                      </li>
                    </ul> -->
                  </li>
                </ul>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>

          <div class="contact-btn-mob">
            <router-link to="/contact" class="cta-btn">Contact Us</router-link>
          </div>
          
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>
<script>
export default {
  name: "HeaderMobile",
  props: ["menu"],
  data: () => ({
    drawer: false,
    group: null,
  }),
};
</script>
<style scoped lang="scss">
.row-container{
  margin:0;
}
.mobile-logo
{
  width:160px;
}
.mobile-container-main {
  background: #ffffff;
  width: 100%;
  height: 70px;
  // position:fixed !important;
  // top:0;
  // left:0;
  a {
    color: rgba(0, 0, 0, 0.87);
    text-decoration: none;
  }
  ul {
    text-decoration: none;
    list-style: none;
    padding-left: 10px;
    font-size: 14px;
    li {
      padding: 10px 0px;
      //   border-bottom: 1px solid #00000026;
      font-weight: 500;
      li {
        font-weight: normal;
        font-size: 11px;
        border-bottom: 1px solid #00000026;
      }
    }
  }
  .disable0 {
    display: none;
  }
  .contact-btn-mob
  {
    text-align: center;
    padding-top:30px;
  }
}
.theme--light.v-expansion-panels .v-expansion-panel--disabled {
  color: rgba(0, 0, 0, 0.87) !important;
}
@media (max-width: 270px) {
  .mobile-logo
{
  width:100%;
}
}
</style>