<template>
  <div class="container-wrap-hero">
    <v-container>
      <!-- <div class="text-center"> -->
        <!-- <v-btn class="btn-fifth" elevation="0" > Locations </v-btn> -->
        <div class="locatio">
          <span class="locations-span"> Location</span>
        </div>
        <h1 class="heroview-heading">Our Presence Across The World</h1>
        <p class="paraview-para">
          Our strategic services are available around the world
        </p>
      <!-- </div> -->
      <div>
      <v-card>
        <v-responsive>
          <v-card-text>
          <img class="map-image" src="@/assets/MAP world large size.png" alt="">
          </v-card-text>
        </v-responsive>
      </v-card>
    </div>
      <!-- <div class="location-row">
        <div class="loc loc-1">
          <h2>UNITED KINGDOM</h2>
        </div>
        <div class="loc loc-2">
          <h2>INDIA</h2>
        </div>
        <div class="loc loc-3">
          <h2>EUROPE</h2>
        </div>
      </div> -->
      <!-- <LocationsSec/> -->
      <v-row class="rowe row-top-padding">
        <v-col lg="6" md="6" sm="12" cols="12">
          <h2 class="heroview-intro-text">Get in Touch with Us</h2>
          <p class="heroview-description-p">
            We provide customized and client-centric offerings. Talk to our
            experts to supercharge your business strategies.
          </p>
          <router-link to="/contact" class="cta-btn">CONTACT US</router-link>
        </v-col>

        <v-col class="col1 get-in-touch-img-container" lg="6" md="6" sm="12" cols="12">
          <img class="Hero-comp-image" width="100%" src="@/assets/CTA.png" alt="" />
          <!-- <img src="@/assets/3 CTA section.png" class="Hero-comp-img" alt=""> -->
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
  
<script>
// import LocationsSec from "@/components/allCommon/LocationsSec.vue";
export default {
  name: "HeroComponent",
  components:{
    // LocationsSec
  }
};
</script>


<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

.theme--light.v-sheet {
  background-color: transparent;
}

.v-sheet.v-card:not(.v-sheet--outlined) {
  box-shadow: none !important;
}

.container-wrap-hero {
  padding: 60px 20px 20px 20px;
  background: transparent linear-gradient(180deg, #717171 0%, #000000 100%) 0% 0% no-repeat padding-box;
}

// .pt-15 {
//   padding: 90px;
// }

.locatio{
  display: flex;
  align-items: center;
  justify-content: center;
}

.locations-span {
  padding: 8px 43px;
  border-radius: 12px 12px 12px 12px;
  color: #ffffff !important;
  background-color: #fc3000 !important;
  opacity: 1;

}

.heroview-heading {
  margin-top: 10px;
  text-align: center;
  // font: normal normal normal 60px Poppins;
  font-size: 3vw;
  font-weight: 500;
  // letter-spacing: -2.64px;
  color: #ffffff;
  text-transform: capitalize;
  opacity: 1;
}

.hero-column {
  position: relative;
  margin-top: 70px;
}

.paraview-para {
  text-align: center;
  // font: normal normal normal 16px/32px Poppins;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
}

.hero-row {
  gap: 40px;
}

// .container-wrap {
//   background: linear-gradient(#717171, #000000);
// }

.heroview-intro-text {
  text-align: left;
  font-size: 2.8vw;
 width: 50%;
  // letter-spacing: 1px;
  color: #ffffff;
  font-weight: 500;
  // line-height: 55px;
  opacity: 1;
}
.map-image{
  width: 100%;
  padding-left: 10%;
  padding-right: 10%;
}

// .united-heading {
//   position: absolute;
//   font: normal normal bold 30px Poppins;
//   letter-spacing: -0.97px;
//   color: #ffffff;
//   text-transform: uppercase;
//   opacity: 1;
//   bottom: 25px;
//   width: 100%;
//   // height: 100%;
//   display: flex;
//   align-items: center;
//   justify-content: center;
// }

.heroview-description-p {
  margin-top: 10px;
  text-align: left;
  // font: normal normal normal 20px/32px Poppins;
width: 60%;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
}

.heroview-img {
  border-radius: 12px;
}

.rowe.row-top-padding {
  // padding-top: 50px;
  position: relative;
}


.location-column-card {
  border-radius: 14px !important;
  outline: none !important;
  position: relative
}

// .col-1{
//   position: absolute;
// }

// .btn-six {
//   margin-top: 50px;
//   border-radius: 15px;
//   padding: 30px 50px 30px 50px !important;
//   text-align: center;
//   font: normal normal normal 16px Poppins;
//   letter-spacing: 0px;
//   color: #ffffff;
//   text-transform: uppercase;
//   opacity: 1;
// }

.get-in-touch-img-container {
  position: relative;
}

.cta-row {
        padding: 50px;

        h2 {
            font-size: 48px;
            font-weight: 400;
            color: #ffffff;
            max-width: 50%;
        }

        p {
            max-width: 90%;
            margin: 25px 0;
            color: #ffffff;
        }

        a.cta-btn {
            // display: inline-block;
            // padding: 15px 35px;
            // text-transform: uppercase;
            // background: #FF3F2D;
            // color: #fff;
            // text-decoration: none;
            // border-radius: 24px;
            // font-weight: 300;
            // margin-top: 45px;
        }
    }



//Added by suraj

.location-row {
  // display: flex;
  // padding: 50px 0;
  // gap: 50px;

  .loc {
    flex: 1;
    background-blend-mode: multiply !important;
    background-size: cover !important;
    background-position: center !important;
    // min-height: 600px;
    aspect-ratio: 4.9/5.5;
    border-radius: 12px;
    display: flex;
    justify-content: center;
    align-items: flex-end;

    h2 {
      font-size: 26px;
      margin-bottom: 25px;
      color:#ffffff;
  }

    background: url('@/assets/uk.jpg'), rgba(0, 0, 0, 0.3);
  }

  .loc-2 {
    background: url('@/assets/in.jpg'), rgba(0, 0, 0, 0.3);
  }

  .loc-3 {
    background: url('@/assets/eu.jpg'), rgba(0, 0, 0, 0.3);
  }
}

// @media (max-width: 768px) {
//   .pt-15 .rowe h2 {
//     font-size: 32px;
//   }

//   .pt-15 .rowe p {
//     font-size: 16px;
//   }
// }

@media (max-width: 1648px) {

 .cta-row .get-in-heading{
font: normal normal normal 30px Poppins;

}
.heroview-heading
{
  font-size: 30px;
}

}

@media only screen and (min-width: 320px) and (max-width: 768px) {
      .map-image{
        width: 100%;
        padding: 0;
        margin-bottom: 40px;
      }
    }

      @media only screen and (min-width: 320px) and (max-width: 768px) {
      .heroview-intro-text{
        font-size: 24px;
        width: 100%;
      }

.heroview-description-p{
  font-size: 14px;
  width: 70%;
}
     }
</style>