import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/business-strategy',
    name: 'BusinessStrategy',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/BusinessStrategy.vue')
  },
  {
    path: '/organisational-scaleup',
    name: 'OrganisationalScaleup',
    component: () => import('../views/OrganisationalScaleup.vue')
  },
  {
    path: '/vc-investment',
    name: 'VCInvestment',
    component: () => import('../views/VCInvestment.vue')
  },
  {
    path: '/about',
    name: 'AboutUs',
    component: () => import('../views/AboutView.vue')
  },
  {
    path: '/contact',
    name: 'Contact',
    component: () => import('../views/ContactView.vue')
  },
  // {
  //   path: '/insights',
  //   name: 'Insights',
  //   component: () => import('../views/InsightsView.vue')
  // },

  {
    path: '/industry-expertise',
    name: 'Industry',
    component: () => import('../views/IndustryExpertiseView.vue')
  },

  {
    path: '/Terms',
    name: 'Terms',
    component: () => import('../views/TermsofuseView.vue')
  },


  {
    path: '/Privacy',
    name: 'Privacy',
    component: () => import('../views/PrivacyPolicyView.vue')
  }



]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior(to) {
    if (to) {
      if (to.meta == "noScroll") {
        return
      }
      else if (to.hash) {
        return {
          selector: to.hash
        }
      } else {
        return { x: 0, y: 0 }
      }
    }
  },
  routes
})

export default router
