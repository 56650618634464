<template>
  <div>
    <v-main>
      <!-- <NavbarComponent /> -->
      <!-- <DemoNavbar /> -->
      <HeroComponent />
      <ItemComponent />
      <!-- <CardCarousel /> -->
      <galleryComponent />
      <CardComponent />
  
      <!-- <CarouselSlider /> -->
      <!-- <CardSliderComponent /> -->
      <HeroviewComponent />
      <!-- <FooterComponent /> -->
    </v-main>
  </div>
</template>

<script>
import HeroComponent from "../components/homepage/HeroComponent.vue";
import ItemComponent from "../components/homepage/ItemComponent.vue";
import galleryComponent from "@/components/homepage/galleryComponent.vue";
// import CardCarousel from "../components/homepage/CardCarousel.vue";
import CardComponent from "../components/homepage/CardComponent.vue";

// import CarouselSlider from "../components/homepage/CarouselSlider.vue";
import HeroviewComponent from "../components/homepage/HeroviewComponent.vue";
// import NavbarComponent from "../components/homepage/NavbarComponent.vue";
// import FooterComponent from "@/components/homepage/FooterComponent.vue";

export default {
  name: "homePage",

  components: {
    HeroComponent,
    ItemComponent,
    galleryComponent,
    // CardCarousel,
    CardComponent,

    // CarouselSlider,
    // NavbarComponent,
    HeroviewComponent,
    // FooterComponent,
  },
};
</script>