<template>
  <div>
    <div
      class="header-navigation"
      :class="{
        sticky: scrolled > 100,
      }"
    >
      <v-container>
        <v-row class="header-row" align="center">
          <v-col class="py-7">
            <div class="logo">
              <router-link to="/"
                ><img
                  src="@/assets/Narmada logo_narmada orange copy 5.png"
                  width="160"
                  alt=""
              /></router-link>
            </div>
            <div class="logo-orange">
              <router-link to="/"
                ><img src="@/assets/Narmada logo_narmada orange copy 4.png" width="160" alt=""
              /></router-link>
            </div>
          </v-col>
          <v-col class="pa-0">
            <div class="nav-items">
              <div v-for="(menu, i) in menuData" :key="i" class="flex-grow">
                <div
                  class="nav-item py-11 flex-grow"
                  v-if="menu.subMenu == null"
                >
                  <router-link :to="menu.link">{{ menu.title }}</router-link>
                </div>
                <div class="nav-item has-dropdown py-11 flex-grow" v-else>
                  <!-- <router-link :to="menu.link">{{ menu.title }}</router-link> -->
                  <a :href="menu.link">{{ menu.title }}</a>
                  <div class="mega-menu">
                    <v-container class="container">
                      <v-row
                        align-content="center"
                        justify="center"
                        class="justify-center py-8"
                      >
                        <v-col
                          v-for="(item, j) in menu.subMenu"
                          :key="j"
                          class="px-14"
                        >
                          <div v-if="item.hasOwnProperty('descr')">
                            <h2>
                              {{ item.title }}
                              <i
                                class="fa fa-arrow-right"
                                aria-hidden="true"
                              ></i>
                            </h2>
                            <p class="pt-10">
                              {{ item.descr }}
                            </p>
                          </div>
                          <div v-else>
                            <p class="sub-title">
                              <!-- <router-link :to="item.link">
                                {{ item.title }}
                              </router-link> -->
                              <a :href="item.link">
                                {{ item.title }}
                              </a>
                            </p>
                            <ul>
                              <!-- <li v-for="(sub, k) in item.subMenu" :key="k"> -->
                                <!-- <router-link :to="sub.link">
                                  {{ sub.title }}
                                </router-link> -->
                                <!-- <a :href="sub.link">
                                  {{ sub.title }}
                                </a>
                              </li> -->
                            </ul>
                          </div>
                        </v-col>
                      </v-row>
                    </v-container>
                  </div>
                </div>
              </div>
            </div>
          </v-col>
          <v-col class="text-right">
            <router-link to="/contact" class="cta-btn">Contact Us</router-link>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <headerMobile :menu="menuData" class="mob-menu" />
  </div>
</template>

<script>
import headerMobile from "./headerMobile.vue";
export default {
  name: "HeaderComponent",
  components: {
    headerMobile,
  },
  data() {
    return {
      scrolled: null,
      menuData: [
        {
          title: "About",
          link: "/about",
          subMenu: null,
        },
        {
          title: "Services",
          link: "/business-strategy",
          subMenu: [
            {
              title: "Our Services",
              descr:
                "We provide a myriad of expert services to maximize value and drive change.",
            },
            {
              title: "Business Strategy",
              link: "/business-strategy",
              subMenu: [
                {
                  title: "Financial",
                  link: "/business-strategy#business",
                },
                {
                  title: "Legal, Compliance & Taxation",
                  link: "/business-strategy#business",
                },
                {
                  title: "Operational",
                  link: "/business-strategy#business",
                },
                {
                  title: "Business Expansion",
                  link: "/business-strategy#business",
                },
              ],
            },
            {
              title: "Organisational Scale-up",
              link: "/organisational-scaleup",
              subMenu: [
                {
                  title: "Back Office Automation",
                  link: "/organisational-scaleup#1",
                },
                {
                  title: "Financial Planning & Analysis",
                  link: "/organisational-scaleup#2",
                },
                {
                  title: "Performance Matrix",
                  link: "/organisational-scaleup#3",
                },
                {
                  title: "Financial Operations",
                  link: "/organisational-scaleup#4",
                },
                {
                  title: "Legal, Statutory & Compliance",
                  link: "/organisational-scaleup#5",
                },
                {
                  title: "Taxation",
                  link: "/organisational-scaleup#6",
                },
              ],
            },
            {
              title: "M&A and VC Investment",
              link: "/vc-investment",
              subMenu: [
                {
                  title: "Legal & Statutory",
                  link: "/vc-investment#vc",
                },
                {
                  title: "Financial",
                  link: "/vc-investment#vc",
                },
                {
                  title: "Operational",
                  link: "/vc-investment#vc",
                },
                {
                  title: "Post-Acquisition Integration",
                  link: "/vc-investment#vc",
                },
              ],
            },
          ],
        },
        {
          title: "Industry Expertise",
          link: "/industry-expertise",
          subMenu: null,
        },
      ],
    };
  },
  methods: {
    handleScroll() {
      this.scrolled = window.scrollY;
      // localStorage.setItem('scrollvalue', this.scrolled)
    },
  },
  created() {
    window.addEventListener("scroll", this.handleScroll);
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  },
};
</script>

<style lang="scss" scoped>
.header-navigation {
  position: absolute;
  width: 100%;
  background: transparent;
  z-index: 1000;

  .header-row {
    // padding: 20px 50px;
    // padding: 20px 0px;
    color: #fff;

    @media only screen and (min-width: 1024px) and (max-width: 1438px) {
      .mega-menu {
        h2 {
          font-size: 16px;
          i {
            color: #ff3f2d;
            font-size: 16px;
            padding-left: 20px;
          }
        }
      }
    }

    @media only screen and (min-width: 1439px) and (max-width: 1903px) {
      // padding: 10px 20px;
    }

    a.cta-btn {
      // display: inline-block;
      // padding: 15px 35px;
      // text-transform: uppercase;
      // background: #ff3f2d;
      // color: #fff;
      // text-decoration: none;
      // border-radius: 12px;
      // font-weight: 300;
    }

    .nav-items {
      display: flex;
      justify-content: space-between;

      .flex-grow {
        // flex-grow: 1;
        // flex-basis: 0;
        display: flex;
        // flex: 1 0 1;
        flex: 1;
        justify-content: center;
      }

      .nav-item {
        a {
          color: inherit;
          text-decoration: none;
          display: inline-block;
          //   padding: 10px 25px;
          font-size: 16px;
          font-weight: 300;
          letter-spacing: 0.2px;

          &:hover {
            // color: #FF3F2D;
          }
        }
      }

      .nav-item.has-dropdown {
        // position: relative;

        &:hover {
          .mega-menu {
            display: initial;
          }
        }

        .mega-menu {
          position: absolute;
          min-width: 250px;
          display: none;

          background: #fff;
          left: 0px;
          top: 111px;
          width: 100%;
          color: #000000;

          h2 {
            i {
              color: #fc3000;
              font-size: 18px;
              padding-left: 20px;
            }
          }
          ul {
            padding: 0;
          }

          ul li {
            list-style: none;
            padding: 8px 0 8px 35px;
            transition: 0.5s all ease-in-out;
            // border-left: 25px solid transparent;
            font-size: 14px;
            position: relative;
            &:before {
              content: "";
              width: 24px;
              height: 24px;
              background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAABHNCSVQICAgIfAhkiAAAAItJREFUSEtj/G+v+5+B2uA/QwzjoctLQcYyDk0LeLh6GSSEDjIw/jpFGx+ICFxlEOB7wfD3X82oBdjT32gQEcyXo0E0GkQEQ4CggtFUNNKCyE43GuxlHk4jBk52d4LeJ0YBJ8cHBna2H+AaDab+f7KnLwMDUz4x+olWg2JBirM4wx82RaI1E6Xw+w0AAOyeBa5V3dYAAAAASUVORK5CYII=);
              position: absolute;
              left: 0;
            }

            &:hover {
              //   border-left: 8px solid #ff3f2d;

            }

            a {
              //   color: #707070;
              //   display: block;
              padding: 5px 15px;
              font-size: 14px;
            }
          }
          .sub-title {
            position: relative;

            a {
              font-size: 18px;
              font-weight: 500;
            }

            a:hover {
              // height: 1px;
              color: #ff3f2d;
              cursor: pointer;
            }
            &:after {
              content: "";
              position: absolute;
              bottom: 0;
              left: 0;
              width: 80%;
              // height: 2px;
              background: #ff3f2d;
            }
            
          }
        }
      }
    }
  }

  .logo {
    opacity: 1;
  }

  .logo-orange {
    opacity: 0;
    display: none;
  }
}

.header-navigation.sticky {
  position: fixed !important;
  top: 0 !important;
  background: #fff;
  box-shadow: 0 10px 30px hsl(0deg 0% 51% / 16%);

  .logo {
    opacity: 0;
    display: none;
  }

  .logo-orange {
    opacity: 1;
    display: initial;
  }

  .header-row {
    .nav-items {
      .nav-item {
        a {
          color: #000;
          font-weight: 400;
        }
      }
    }
  }
}
.mob-menu {
  display: none;
}

@media(min-width:899px) and (max-width: 1263px){
  .nav-item a{
    font-size: 14px !important;
  }
}

@media (max-width: 900px) {
  .header-navigation {
    display: none;
  }
  .mob-menu {
    display: block;
  }
}
@media(min-width:899px) and (max-width: 1438px){

  .mega-menu {
        h2 {
          font-size: 10px;
          i {
            color: #ff3f2d;
            font-size: 16px;
            padding-left: 20px;
          }
        }
      }
    }
</style>